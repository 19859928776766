/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

.main-content {
  .main-title {
    font-size: 1.2rem;
  }
}

.form {
  width: 60%;
}

.header-nav {
  background: #fff;
}

@media screen and (max-width: 768px) {
  .form {
    width: 90%;
  }
}

@media screen and (max-width: 991px) {
  .dropdown {
    margin-top: 10px;
  }
  .form {
    width: 90%;
  }
}

.footer-links {
  text-decoration: none;
  color: black !important;
}

.gg-recaptcha {
  width: 304px !important;
  max-width: 304px !important;
  -ms-transform: scale(0.8, 0.8);
  -webkit-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
  margin: 0px;
  padding: 0px;
}

.spinner {
  margin-left: 50%;
  margin-top: 100px;
}

.error-border {
  border: 1px solid #e90721 !important;
  box-sizing: border-box !important;
  box-shadow: inset 0 0 4px #e90721 !important;
}
